<template>
	<div class="container">
		<Nav :curIndex="7"></Nav>
		<div class="account_right">
			<div class="form-box">
				<div class="title">Change Password</div>
				<el-form :model="form" :rules="rules" ref="form" label-position="top" :hide-required-asterisk="true" class="form">
					<el-form-item label="Old Password" prop="originalPassword">
					    <el-input v-model="form.originalPassword" show-password placeholder=""></el-input>
					</el-form-item>
				    <el-form-item label="New Password" prop="password">
				        <el-input v-model="form.password" show-password placeholder=""></el-input>
				    </el-form-item>
				    <el-form-item label="Password Again" prop="confirmPassword">
				        <el-input v-model="form.confirmPassword" show-password placeholder=""></el-input>
				    </el-form-item>
				    <el-form-item>
				        <div class="submit-btn" @click="submit">Submit</div>
				    </el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
		components: {
		    Nav: () => import('@/views/account/nav.vue')
		},
        data() {
			const checkPassword = (rule, value, callback) => {
			    if (!value) {
			        callback(new Error('Please enter password again'))
			    } else if (value != this.form.password) {
			        callback(new Error('The passwords entered twice are inconsistent'))
			    } else {
			        callback()
			    }
			}
            return {
				form: {
					originalPassword: '',
				    password: '',
				    confirmPassword: ''
				},
				rules: {
					originalPassword: [
						{ required: true, message: 'Please enter the original password', trigger: 'blur' }
					],
				    password: [
				        { required: true, message: 'Please enter a new password', trigger: 'blur' }
				    ],
				    confirmPassword: [
				        { validator: checkPassword, trigger: 'blur' }
				    ]
				}
            }
        },
        methods: {
            submit() {
				this.$refs.form.validate((valid) => {
				    if(valid) {
				        this.$api.changePassword(this.form,true).then(res => {
                            this.$refs.form.resetFields()
				            this.$message({
				                message: res.message,
				                type: 'success'
				            })
				        })
				    }
				})
			}
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .container {
        	display: flex;
        	width: 100%;
        	box-sizing: border-box;
        	padding: 4rem 0;
        	
        	.account_right {
        		width: 79%;
        		box-sizing: border-box;
        		padding-left: 3rem;
        		
        		.form-box {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 3rem;
        				font-size: 2rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.form {
        			    width: 100%;
        			    
        			    /deep/ .el-form-item {
        			        margin-bottom: 2.5rem;
        			    }
        			    
        			    .submit-btn {
        			        display: inline-block;
        			        min-width: 32rem;
        			        height: 5rem;
        			        line-height: 5rem;
        			        box-sizing: border-box;
        			        padding: 0 2.5rem;
        			        border-radius: 4px;
        			        font-size: 1.4rem;
        			        color: #FFFFFF;
        			        text-align: center;
        			        white-space: nowrap;
        			        background-color: #333333;
        			        cursor: pointer;
        			    }
        			}
        		}
        	}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .container {
        	width: 100%;
        	box-sizing: border-box;
        	padding: 2rem $h5-box-padding;
        	
        	.account_right {
        		width: 100%;
        		
        		.form-box {
        			width: 100%;
        			
        			.title {
        				width: 100%;
        				margin-bottom: 2rem;
        				font-size: 1.8rem;
        				color: #333333;
        				word-wrap: break-word;
        			}
        			
        			.form {
        			    width: 100%;
        			    
        			    /deep/ .el-form-item {
        			        margin-bottom: 2.5rem;
        			    }
        			    
        			    .submit-btn {
        			        width: 100%;
        			        height: 4.5rem;
        			        line-height: 4.5rem;
        			        box-sizing: border-box;
        			        padding: 0 2rem;
        			        border-radius: 4px;
        			        font-size: 1.4rem;
        			        color: #FFFFFF;
        			        text-align: center;
        			        white-space: nowrap;
        			        background-color: #333333;
        			        cursor: pointer;
        			    }
        			}
        		}
        	}
        }
    }
</style>